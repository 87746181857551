@media (max-width: $screen-sm) {
    // sm-view
    .popup {
        max-width: 290px;
        max-height: 95vh;
        overflow-y: scroll;

        .popup-form { 
            width: 290px;
            .popup-form-holder { 
                padding: 40px 20px 38px;
                .icon {
                    svg {
                        width: 50px;
                        height: 50px;
                    }
                }
                .heading { 
                    font-size: 18px;
                    line-height: 140%;
                    margin: 0 0 11px;
                }

                .note {
                    line-height: 158%;
                }
            }
        }

        .added-to-cart-container { 
            padding: 39px 20px 8px;
            .added-to-cart-container-holder { 
                .heading { 
                    font-size: 18px;
                    line-height: 140%;
                    margin: 0 0 22px;
                }

                .item { 
                    margin: 0 0 10px;
                    .item-holder { 
                        align-items: flex-start;
                        .image { 
                            width: 88px;
                            height: 80px;
                        }

                        .desc { 
                            width: calc(100% - 88px);
                            .desc-holder {
                                .vendor-code {
                                    font-size: 11px;
                                    margin: 0 0 3px;
                                }

                                .name {
                                    font-size: 12px;
                                    line-height: 140%;
                                    height: 50px;
                                    overflow: hidden;
                                }

                                .price { 
    
                                    .value { 

                                    }
                                }
                            }
                        }
                    }
                }

                .actions { 
                    margin: 0;
                    .action {
                        width: 100%;
                        margin: 0 0 11px;
                        .btn {
                            padding: 8px 10px;
                        }
                    }
                }
            }
        }

        .popup-addon {
            width: 290px;
            height: 400px;
            overflow: hidden;
            padding: 23px 20px;
            .popup-addon-hodler {
                .items-sider-block {
                    overflow: visible;
                    margin: 0;
                    padding: 0 0 21px;
                    .items-slider-block-holder {

                        .common-heading-block {
                            margin: 0 0 19px;
                        }

                        .slick-slider {
                            width: calc(100% + 6px);
                            margin-left: -3px;
                            box-sizing: border-box;
                            padding: 0 108px 0 0;
                            .slick-list {
                                overflow: visible;
                                .slick-track {
                                    .slick-slide {
                                        padding: 0 3px;
                                        >div {
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
