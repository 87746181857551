.pagination {
    display: inline-block;
	ul {
        display: inline-block;
        padding: 0;
        margin: 0;
        list-style: none;
        >li {
            display: inline-block;
            float: left;
            a {
                display: inline-block;
            }
            &.next, &.prev {
                &.disabled {
                    a {
                        color: #E0E0E0;
                        pointer-events: none;
                        touch-action: none;
                        cursor: default;
                        &:hover {
                            color: #E0E0E0;
                            background: none;
                        }
                    }
                }
    			a {
                    color: $brand-secondary;
                    text-decoration: none;
                    transition: all 300ms;
    				svg {
                        width: 24px;
                        height: 24px;
    				}
                    &:hover {
                        color: black;
                        transition: all 300ms;
                    }
    			}
    		}
            &.next {
                a {

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

    		&.page {
                margin: 0 5px;
                &.current {
                    a {
                        background: white;
                        pointer-events: none;
                        touch-action: none;
                        &:hover {
                            cursor: default;
                            background: white;
                            color: $gray-base;
                        }
                    }
                }
    			a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 36px;
                    height: 36px;
                    border: none;
                    box-sizing: border-box;
                    font-size: 16px;
                    line-height: 160%;
                    color: $gray-base;
                    text-decoration: none;
                    border-radius: 6px;
                    transition: all 300ms;
                    &:hover {
                        background: $brand-primary;
                        color: white;
                    }
    			}
    		}

    		&.middle {
    			a {
                   
    			}
    		}
        }



	}
}
