.footer-holder { 
    display: inline-block;
    width: 100%;
	.footer-main { 
        display: inline-block;
        width: 100%;
        padding: 40px 0 22px;
		.footer-main-holder { 
            display: inline-block;
            width: 100%;
            .footer-about-company { 
                float: left;
                width: 294px;
                .footer-about-company-holder { 
                    display: inline-block;
                    width: 100%;
                    .logo { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 21px;
                        a { 

                            svg { 

                            }
                        }
                    }

                    .full-name { 
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 140%;
                        color: white;
                        margin: 0 0 19px;
                    }

                    .contacts { 
                        display: inline-block;
                        width: 100%;
                        .contact-line { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 10px;
                            .name { 
                                display: inline-block;
                                width: 100%;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 140%;
                            }

                            .info { 
                                display: inline-block;
                                width: 100%;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 160%;
                                a { 
                                    color: white;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                }
                            }
                        }
                    }

                    .policy { 
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 140%;
                        margin: 10px 0 0;
                        a { 
                            color: $gray;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                    }
                }
            }

            .footer-menu { 
                float: right;
                width: calc(95.8% - 294px);
                .footer-menu-holder { 

                    .menu-col.footer-company-menu {
                        width: 25.5%;
                    }
                    .menu-col.footer-catalog-menu {
                        width: 33.8%;
                        .menu-col-holder { 
                            nav { 
                                width: 83%;
                            }
                        }
                    }
                    .menu-col.footer-service-menu {
                        width: 40.7%;
                    }
                    .menu-col { 
                        float: left;
                        .menu-col-holder { 
                            display: inline-block;
                            width: 100%;
                            .name { 
                                display: inline-block;
                                 width: 100%;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 160%;
                                color: white;
                                margin: 0 0 6px;
                            }

                            nav { 
                                display: inline-block;
                                width: 100%;
                                ul { 
                                    display: inline-block;
                                    width: 100%;
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;
                                    li { 
                                        margin: 0 0 6.5px;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 140%;
                                        a { 
                                            color: $gray;
                                            &:hover {
                                                color: $brand-primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	}

	.footer-bottom { 
        display: inline-block;
        width: 100%;
		.footer-bottom-holder { 
            display: inline-block;
            width: 100%;
            border-top: 1px solid $brand-secondary;
            padding: 15px 0 0;
            .copyright { 
                float: left;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                padding: 10px 0 0;
            }

            .studio { 
                float: right;
                a { 
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                    color: $gray;
                    &:hover {
                        color: $brand-primary;
                    }
                    .text { 
                        float: left;
                    }

                    .icon { 
                        float: left;
                        margin-left: 10px;
                        svg { 

                        }
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/footer";
@import "../../media/mobile/includes/base/footer";
