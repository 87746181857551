@media (max-width: $screen-lg) {
    // lg-md view
    .header-holder { 
        .header-main { 
            height: 100%;
            .wrapper-inside {
                height: 100%;
            }
            .header-main-holder { 
                position: relative;
                height: 100%;

                .mobile-menu {
                    padding: 15px 0 0;
                    margin: 0 39px 0 0;
                }

                .logo {
                    margin: 0 61px 0 0;
                    a {
                        svg {
                            width: 72px;
                            height: auto;
                        }
                    }
                }

                .call-order {
                    float: left;
                    padding: 21px 0 0;
                    a {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160%;
                        display: flex;
                        align-items: center;
                        .icon {
                            float: left;
                        }

                        .text {
                            float: left;
                            margin-left: 11px;
                        }
                    }
                }

                .quick-search { 
                    width: 24px;
                    height: 24px;
                    margin: 0 0 0 41px;
                    padding: 20px 0 0;
                    &.open {
                        .quick-search-button {
                            a.open {
                                opacity: 0;
                                touch-action: none;
                                pointer-events: none;
                            }
                            a.close {
                                opacity: 1;
                                touch-action: auto;
                                pointer-events: all;
                            }
                        }
                        .quick-search-form {
                            height: 60px;
                            opacity: 1;
                            transition: all 500ms;
                        }
                    }
                    .quick-search-button {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        a.open, a.close {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        a.close {
                            opacity: 0;
                            touch-action: none;
                            pointer-events: none;
                        }
                    }

                    .quick-search-form {
                        position: absolute;
                        left: -5.6525%;
                        top: 100%;
                        width: 111.305%;
                        height: 0;
                        background: white;
                        z-index: 1;
                        box-sizing: border-box;
                        padding: 10px 5.6525%;
                        opacity: 0;
                        overflow: hidden;
                        transition: all 500ms;
                        form { 
                            .form-field { 

                                label.input { 
                                    input { 
                                        height: 40px;
                                    }
                                }
                            }
                        }
                    }
                }

                .header-peronal { 
                    .personal { 
                        padding: 21px 0 0;
                    }

                    .cart { 
                        padding: 20px 0 0;
                        margin: 0 0 0 41px;
                        a {
                            .text {
                                min-width: 75px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
