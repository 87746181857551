@media (max-width: $screen-lg) {
    // lg-md view
    .footer-holder { 
        .footer-main { 
            padding: 20px 0 19px;
            .footer-main-holder { 
                .footer-about-company { 
                    width: 100%;
                    .footer-about-company-holder { 
                        .logo {
                            margin: 0 32px 0 0;
                            padding: 10px 0 0;
                            width: 88px;
                            float: left;
                            a {
                                display: inline-block;
                                width: 100%;
                                svg {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }

                        .full-name { 
                            float: left;
                            width: 30%;
                            margin: 0 33px 0 0;
                            padding: 10px 0 0;
                        }

                        .contacts {
                            float: left;
                            width: 23%;
                            padding: 10px 0 0;
                        }

                        .policy { 
                            float: right;
                            width: 20%;
                            text-align: right;
                        }
                    }
                }

                .footer-menu { 
                    
                }
            }
        }

        .footer-bottom { 
            .footer-bottom-holder { 
                .copyright { 
                   
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
