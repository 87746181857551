/*buttons*/

.btn {
	text-decoration: none;
	font-weight: 700;
	font-size: 16px;
	line-height: 160%;
	font-family: inherit;
	background: $bg-primary;
	color: $gray-base;
	padding: 8px 10px;
	box-sizing: border-box;
    display: inline-block;
	letter-spacing: 0;
	border-radius: 6px;
	border: 1px solid $bg-primary;
	outline: none;
	transition: all 300ms;
	&:hover {
		cursor: pointer;
		background: $bg-secondary;
		color: $gray-base;
		transition: all 300ms;
	}
}
.btn-primary {
	background: $brand-primary;
	border: 1px solid $brand-primary;
	color: $gray-lighter;
	&:hover {
		background: $brand-primary-accent;
		border: 1px solid $brand-primary-accent;
		color: $gray-lighter;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		&:hover {
			background: $brand-primary;
			border: 1px solid $brand-primary;
			color: white;
		}
	}
}
.btn-secondary {
	background: $brand-secondary;
	border: 1px solid $brand-secondary;
	color: $gray-lighter;
	&:hover {
		background: $brand-secondary-accent;
		border: 1px solid $brand-secondary-accent;
		color: $gray-lighter;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-secondary;
		&:hover {
			background: $brand-secondary;
			border: 1px solid $brand-secondary;
			color: white;
		}
	}
}