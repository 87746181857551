.fancybox-container.custom-fancybox {
    .fancybox-content {
        padding: 0;
    }
    button.fancybox-button.fancybox-close-small {
        display: none;
    }
}

.popup {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    max-width: 760px;
    .custom-close {
        position: absolute;
        right: 14px;
        top: 15px;
        a {
            display: inline-block;
            color: $gray;
            &:hover {
                color: $brand-primary;
            }
        }
    }

    .general-popup-container {
        .general-popup-container-holder {

        }
    }

    .popup-form { 
        display: inline-block;
        width: 430px;
        .popup-form-holder { 
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            padding: 38px 30px 38px;
            .icon {
                display: inline-block;
                width: 100%;
                text-align: center;
                margin: 0 0 10px;
            }

            .heading { 
                display: inline-block;
                width: 100%;
                margin: 0 0 13px;
                text-align: center;
            }
    
            .note { 
                display: inline-block;
                width: 100%;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                color: $gray-dark;
                text-align: center;
                margin: 0 0 14px;
            }
    
            form { 
    
                .form-group { 
    
                    .form-col { 
    
                        
                    }
                }

                .form-field { 
                    margin-bottom: 14px;
                    .input { 

                        .name { 

                        }

                        input { 

                        }
                    }
                }
    
                .action { 
    
                    .submit { 
    
                        .btn { 
                            padding: 13px 10px;
                        }
                    }
    
                    .agree-col { 
    
                        a { 
    
                        }
                    }
                }
            }
        }
    }

    .added-to-cart-container { 
        display: inline-block;
        width: 100%;
        padding: 40px 30px 18px;
        .added-to-cart-container-holder { 
            display: inline-block;
            width: 100%;
            .heading { 
                display: inline-block;
                width: 100%;
                margin: 0 0 19px;
            }
    
            .item { 
                display: inline-block;
                width: 100%;
                margin: 0 0 11px;
                .item-holder { 
                    display: flex;
                    width: 100%;
                    align-items: center;
                    .image { 
                        width: 110px;
                        height: 100px;
                        float: left;
                        .image-holder { 
                            width: 100%;
                            height: 100%;
                            a { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                img { 
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
    
                    .desc { 
                        width: calc(100% - 110px);
                        float: left;
                        box-sizing: border-box;
                        padding-left: 10px;
                        .desc-holder { 
    
                            .vendor-code { 
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%;
                                color: $gray;
                                margin: 0 0 3px;
                            }
    
                            .name { 
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 160%;
                                margin: 0 0 5px;
                                a { 
    
                                }
                            }
    
                            .price { 
    
                                .value { 
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 800;
                                    line-height: 160%;
                                }
                            }
                        }
                    }
                }
            }
    
            .actions { 
                display: inline-block;
                width: 100%;
                margin: 0 -5px;
                .action { 
                    float: left;
                    margin: 0 5px;
                    width: 186px;
                    .btn { 
                        width: 100%;
                        padding: 15px 10px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160%;
                        text-align: center;
                    }
                }
            }
        }
    }

    .popup-addon {
        width: 760px;
        height: 480px;
        box-sizing: border-box;
        padding: 20px 29px;
        overflow: hidden;
        .popup-addon-hodler {
            .items-sider-block {
                .items-slider-block-holder {
                    .common-heading-block {
                        margin: 0 0 19px
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/popups/custom_fancybox";
@import "../../media/mobile/includes/popups/custom_fancybox";
