@media (max-width: $screen-lg) {
    // lg-md view
    .popup {
        max-width: 506px;
        .popup-addon {
            width: 506px;
            height: 394px;
            overflow: hidden;
            .popup-addon-hodler {
                .items-sider-block {
                    overflow: visible;
                    .items-slider-block-holder {

                        .slick-slider {
                            width: calc(100% + 10px);
                            margin-left: -5px;
                            .slick-list {
                                overflow: visible;
                                .slick-track {
                                    .slick-slide {
                                        padding: 0 5px;
                                        >div {
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
