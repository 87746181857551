@font-face {
    font-family: 'Manrope';
	src: url('./Manrope-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: 'Manrope';
	src: url('./Manrope-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
    font-family: 'Manrope';
	src: url('./Manrope-SemiBold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
    font-family: 'Manrope';
	src: url('./Manrope-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
    font-family: 'Manrope';
	src: url('./Manrope-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Manrope';
	src: url('./Manrope-ExtraLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
    font-family: 'Manrope';
	src: url('./Manrope-Light.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}
