@media (max-width: $screen-sm) {
    // sm-view
    .footer-holder { 
        .footer-main { 
            padding: 31px 0 19px;
            .footer-main-holder { 
                .footer-about-company { 
                    width: 100%;
                    .footer-about-company-holder { 
                        .logo {
                            margin: 0 0 19px 0;
                            padding: 0 0 0;
                            width: 100%;
                            float: left;
                            text-align: center;
                            a {
                                display: inline-block;
                                width: auto;
                                svg {
                                    width: 101px;
                                    height: auto;
                                }
                            }
                        }

                        .full-name { 
                            float: left;
                            width: 100%;
                            margin: 0 0 19px 0;
                            padding: 0 0 0;
                            text-align: center;
                        }

                        .contacts {
                            float: left;
                            width: 100%;
                            padding: 0 0 0;
                            text-align: center;
                        }

                        .policy { 
                            float: right;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }

                .footer-menu { 
                    
                }
            }
        }

        .footer-bottom { 
            .footer-bottom-holder { 
                .copyright { 
                    width: 100%;
                    text-align: center;
                    padding: 0 20%;
                    box-sizing: border-box;
                    margin: 0 0 23px;
                }

                .studio { 
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
