form {

    .action {
        display: inline-block;
        width: 100%;
        .agree-col {
            padding-top: 15px;
            padding-left: 20px;
            box-sizing: border-box;
            width: calc(100% - 200px);
            float: left;
            margin-bottom: 20px;
            font-size: 12px;
            line-height: 140%;
            color: $gray;
            a {
                color: $gray-dark;
                &:hover {
                    color: $brand-primary;
                }
            }
        }
        .submit {
            width: 200px;
            float: left;
            .btn {
                width: 100%;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                line-height: 160%;
                text-transform: uppercase;
            }
        }
    }
    .action.full {
        .agree-col {
            width: 100%;
            padding: 0;
            margin: 10px 0 0;
            text-align: center;
        }

        .submit {
            width: 100%;
        }
    }

    .note-block {
        display: inline-block;
        width: 100%;
        .note {
            display: inline-block;
            width: 100%;
            position: relative;
            padding-top: 10px;
            margin-bottom: 10px;
            &.success {
                color: $brand-success;
                &:before {
                    border-color: $brand-success;
                }
            }
            &.error {
                color: $brand-danger;
                &:before {
                    border-color: $brand-danger;
                }
            }
            &.warning {
                color: $brand-warning;
                &:before {
                    border-color: $brand-warning;
                }
            }
            &:before {
                content: "";
                display: inline-block;
                width: 88px;
                height: 0;
                border-top: 2px solid $gray;
                position: absolute;
                left: 0;
                top: 0;
            }
    
        }
    }
    

    .form-group {
        display: inline-block;
        width: calc(100% + 20px);
        margin-left: -10px;
    	.form-col {
            float: left;
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
            &.col-50 {
                width: 50%;
            }
            &.col-33 {
                width: 33.333333%;
            }
            &.col-66 {
                width: 66.666666%;
            }
    	}
    }

    .form-field {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        label.select, label.input, label.checkcontainer, label.file-upload {
            display: inline-block;
            width: 100%;
            position: relative;

            &.error {
                .name {
                    color: $brand-danger;
                }
            }
            &.success {
                .name {
                    color: $brand-success;
                }
            }
            &.warning {
                .name {
                    color: $brand-warning;
                }
            }

            .name {
                display: inline-block;
                width: 100%;
                margin-bottom: 6px;
                mark {
                    color: $brand-primary;
                    background: none;
                    font-weight: bold;
                }
            }
            .note {
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: normal;
                font-size: $font-size-small;
                line-height: calc($line-height-base/2);
                color: $gray;
                position: absolute;
                top: calc(100% + 5px);
                left: 0;
            }
        }

        label.checkcontainer {
            width: 100%;
            display: inline-block;
            position: relative;
            cursor: pointer;
            font-style: normal;
            font-weight: normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.radio {
                .checkmark {
                    border-radius: 50%;
                }
            }

            &.error {
                .checkmark {
                    border-color: $brand-danger;
                }
            }
            &.success {
                .checkmark {
                    border-color: $brand-success;
                }
            }
            &.warning {
                .checkmark {
                    border-color: $brand-warning;
                }
            }


            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                &:checked ~ .checkmark {
                    background-color: $brand-primary;
                    border-color: $brand-primary;
                    &::after {
                        opacity: 1;
                        transition: all 300ms ease;
                    }
                }
            }
            &:hover input ~ .checkmark {
                background-color: $brand-primary;
                border-color: $brand-primary;
                transition: all 300ms;
            }
            .checkmark {
                float: left;
                left: 0px;
                top: 0px;
                height: 18px;
                width: 18px;
                box-sizing: border-box;
                background-color: $bg-secondary;
                border: none;
                border-radius: 4px;
                border: 1px solid $gray-light;
                position: relative;
                transition: all 300ms;
                &::after {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    opacity: 0;
                    left: 50%;
                    top: 50%;
                    width: 2px;
                    height: 6px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: translate(-50%, -50%) rotate(45deg);
                    -ms-transform: translate(-50%, -50%) rotate(45deg);
                    transform: translate(-50%, -50%) rotate(45deg);
                    transition: all 300ms ease;
                }
            }
            .name {
                margin: 0;
                float: left;
                width: calc(100% - 18px - 10px);
                margin-left: 10px;
            }
        }


        label.select {

            &.error {
                .selectric-selectric-select {
                    .selectric { 
                        border-color: $brand-danger;
                        .label {
                            color: $brand-danger;
                        }
                    }
                }
            }
            &.success {
                .selectric-selectric-select {
                    .selectric { 
                        border-color: $brand-success;
                        .label {
                            color: $brand-success;
                        }
                    }
                }
            }
            &.warning {
                .selectric-selectric-select {
                    .selectric { 
                        border-color: $brand-warning;
                        .label {
                            color: $brand-warning;
                        }
                    }
                }
            }

            .selectric-selectric-select {
                .selectric {
                    background: $bg-secondary;
                    border: 1px solid #E7E9EC;
                    border-radius: 4px;
                    box-sizing: border-box;
                    height: 50px;
                    .label {
                        font-family: $main_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: $font-size-base;
                        line-height: 50px;
                        color: $gray-dark;
                        display: block;
                        margin: 0 40px 0 13px;
                        height: 100%;
                        width: calc(100% - 60px);
                    }
                    .button {
                        height: 48px;
                        width: 40px;
                        border-left: 1px solid #CED2D5;
                        box-sizing: border-box;
                        transition: all 300ms;
                        &:hover {
                            background-color: $gray-lighter;
                            transition: all 300ms;
                        }
                        &:after {
                            border: none;
                            background-image: url(../img/select-shevron.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: auto;
                            background-clip: border-box;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .selectric-items {
                    background: $bg-secondary;
                    border: 1px solid #E7E9EC;
                    .selectric-scroll {
                        ul {
                            position: relative;
                            width: 100%;
                            max-height: 302px;
                            overflow: hidden;
                            li {
                                display: flex;
                                align-items: center;
                                font-family: $main_font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: $font-size-base;
                                line-height: $line-height-base;
                                min-height: 50px;
                                padding: 10px 10px 10px 15px;
                                box-sizing: border-box;
                                &:hover {
                                    background: $gray-lighter;
                                }
                                &.selected  {
                                    background: $brand-primary;
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }

        label.input {
            &.addon {
                input {
                    padding: 0px 30px 0 14px;
                }
            }

            &.error {
                input, textarea {
                    border-color: $brand-danger;
                    color: $brand-danger;
                    @include placeholder {
                        color: $brand-danger;
                    }
                    &:focus {
                        border-color: $brand-danger;
                    }
                }
            }
            &.success {
                input, textarea {
                    border-color: $brand-success;
                    @include placeholder {
                        color: $brand-success;
                    }
                    &:focus {
                        border-color: $brand-success;
                    }
                }
            }
            &.warning {
                input, textarea {
                    border-color: $brand-warning;
                    @include placeholder {
                        color: $brand-warning;
                    }
                    &:focus {
                        border-color: $brand-warning;
                    }
                }
            }

            input, textarea {
                display: inline-flex;
                float: left;
                width: 100%;
                height: 50px;
                align-items: center;
                padding: 0px 10px 0 14px;
                font-family: $main_font;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 160%;
                background: $bg-secondary;
                border: 1px solid #E7E9EC;
                border-radius: 4px;
                box-sizing: border-box;
                transition: all 300ms;
                color: $gray-base;
                &:focus {
                    outline: none;
                    border-color: $brand-primary;
                    transition: all 300ms;
                }
                &:disabled {
                    background: #F6F7FA;
                    color: $gray;
                    &:focus {
                        background: #F6F7FA;
                        color: $gray;
                        border-color: #CED2D5;
                    }
                }
                @include placeholder {
                    font-family: $main_font;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 160%;
                    color: $gray;
                }
            }
            textarea {
                padding: 11px 10px 0 14px;
                height: 300px;
            }

            .addon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 8px;
                button {
                    padding: 0;
                    margin: 0;
                    background: none;
                    outline: none;
                    border: none;
                    color: $gray-base;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-secondary;
                        transition: all 300ms;
                    }
                }
                a {
                    display: inline-block;
                    color: $gray-base;
                    text-decoration: none;
                    transition: all 300ms;
                    .icon {
                        svg {

                        }
                    }
                    &:hover {
                        color: $brand-secondary;
                        transition: all 300ms;
                    }
                }
            }
        }

        label.file-upload {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &.error {
                .filename {
                    color: $brand-danger;
                }
            }
            &.success {
                .filename {
                    color: $brand-success;
                }
            }
            &.warning {
                .filename {
                    color: $brand-warning;
                }
            }

            input {
                display: none;
            }
            .btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 22.4px;
                padding: 13px 12px;
                margin: 0 0 5px;
                .icon {
                    float: left;
                    margin-right: 10px;
                    svg {
                        width: 19px;
                        height: 18px;
                    }
                }
                .text {
                    float: left;
                }
            }
            .filename {
                float: left;
                margin-left: 20px;
                font-size: 12px;
                font-weight: 400;
                line-height: 16.8px;
                &.empty {
                    color: $gray;
                }
            }
        }
    }
}

@import "../media/tablet/includes/forms";
@import "../media/mobile/includes/forms";
