@media (max-width: $screen-sm) {
    // sm-view
    form {
        .form-field {
            label.input {
                input, textarea {
                    padding: 0px 10px 0 16px;
                }

                textarea {
                    padding: 11px 10px 0 16px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
