@media (max-width: $screen-sm) {
    // sm-view
    .slick-slider {
        width: calc(100% + 6px);
        margin-left: -3px;
        .slick-list {
            .slick-track {
                .slick-slide {
                    padding: 0 3px;
                    >div {
                        
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
