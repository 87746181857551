@media (max-width: $screen-sm) {
    // sm-view
    .header-holder { 
        .header-main { 
            .header-main-holder { 
                .mobile-menu {
                    margin: 0 28px 0 0;
                }

                .logo {
                    margin: 0 35px 0 0;
                }

                .call-order {

                }

                .quick-search {
                    margin: 0 0 0 21px;
                }

                .header-peronal { 
                    .cart {
                        margin: 0;
                        a {
                            .icon {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
