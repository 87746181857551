@import "../mixins";

$menu-width: 320px;

/*Mobile menu
-----------------------------------------------------------------------------*/
.mobile-menu {
	.mobile-menu-holder {
		.menu-background {
			position: fixed;
			z-index: 1201;
			top: 0;
			left: 0;
			background: $gray-base;
			opacity: 0.64;
			width: 100%;
			height: 100%;
			@include transition-easy-in-out(250ms);
			&.animated {
				opacity: 0;
				@include transition-easy-in-out(250ms);
			}
			&.closed {
				display: none;
			}
		}
		.menu-body {
			position: fixed;
			z-index: 1202;
			top: 0;
			left: 0;
			display: inline-block;
			float: left;
			width: $menu-width;
			height: 100%;
			box-sizing: border-box;
			padding: 0;
			background: white;
			opacity: 1;
			transform: translateX(0);
			overflow-y: auto;
			@include transition-easy-in-out(350ms);
			&.animated {
				transform: translateX(-$menu-width);
				@include transition-easy-in-out(350ms);
			}
			&.closed {
				display: none;
			}
            &.mobile-offset {

            }
			.heading {
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				padding: 13px 15px 12px;
				.close {
					float: left;
	               	a {
						display: inline-block;
						width: 24px;
						height: 24px;
						svg {
							width: 100%;
							height: 100%;
						}
				   	}
	            }
				.personal {
					float: right;
					margin: 0 6px 0 0;
					a {
						display: inline-block;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 160%;
						.icon {
							float: left;
							width: 22px;
							height: 22px;
							margin: 0 10px 0 0;
							color: $brand-primary;
							svg {
								width: 100%;
								height: 100%;
							}
						}

						.text {
							float: left;
							list-style: 22px;
						}
					}
				}
			}
			.main-body {
				display: inline-block;
				width: 100%;
			}
            /*naib body contents*/
			.mobile-catalog-menu-nav { 
				display: inline-block;
				width: 100%;
				.menu-list { 
					display: inline-block;
					width: 100%;
					>ul { 
						display: inline-block;
						width: 100%;
						margin: 0;
						>li {
							float: left;
							width: 100%;
							>a {
								display: flex;
								align-items: center;
								justify-content: space-between;
								width: 100%;
								box-sizing: border-box;
								padding: 12px 20px;
								background: $gray-light;
								font-size: 16px;
								font-style: normal;
								font-weight: 700;
								line-height: 162%;
								.text { 
									width: calc(100% - 38px);
								}
			
								.icon { 
									width: 18px;
									height: 18px;
									color: $brand-primary;
									svg { 
										width: 100%;
										height: 100%
									}
								}
							}
						}
			
						>li.has-sub-menu { 

							&.open {
								>a {
									background: $brand-primary;
									color: white;
									.icon {
										color: white;
										transition: all 300ms;
										transform: rotate(180deg);
									}
								}
								.sub-menu { 
									max-height: 1000px;
									transition: all 1000ms;
								}
							}
			
							>a { 
								
								.text { 
			
								}
			
								.icon { 
									transition: all 300ms;
									svg { 

									}
								}
							}
			
							.sub-menu { 
								float: left;
								width: 100%;
								max-height: 0px;
								overflow: hidden;
								transition: all 500ms;
								.sub-menu-holder { 
									display: inline-block;
									width: 100%;
									box-sizing: border-box;
									padding: 10px 15px;
									background: $gray-light;
									>ul { 
										display: inline-block;
										width: 100%;
										padding: 0;
										margin: 0;
										>li { 
											display: inline-block;
											width: 100%;
											margin: 0 0 12px;
											&:last-of-type {
												margin: 0;
											}
											>a { 
												font-size: 14px;
												font-style: normal;
												font-weight: 400;
												line-height: 164%;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.mobile-main-menu-nav { 
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				padding: 20px;
				ul { 
					display: inline-block;
					width: 100%;
					padding: 0;
					margin: 0;
					li { 
						display: inline-block;
						width: 100%;
						margin: 0 0 14px;
						&:last-of-type {
							margin: 0;
						}
						a { 
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 154%;
						}
					}
				}
			}
			.contacts-infoes { 
				display: inline-block;
				width: 100%;
				background: $gray-light;
				box-sizing: border-box;
				padding: 20px;
				.contacts-info-line { 
					display: inline-block;
					width: 100%;
					margin: 0 0 11px;
					&:last-of-type {
						margin: 0;
					}
					.heading { 
						display: inline-block;
						width: 100%;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 140%;
					}
			
					.info { 
						display: inline-block;
						width: 100%;
						font-size: 16px;
						font-style: normal;
						font-weight: 800;
						line-height: 160%;
						a { 
			
						}
					}
				}
			}
			
			.order-call { 
				display: inline-block;
				width: 100%;
				.btn { 
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 160%;
					border-radius: 0;
					padding: 14px 10px;
					.icon { 
						margin-right: 10px;
						width: 16px;
						height: 16px;
						svg { 
							width: 100%;
							height: 100%
						}
					}
			
					.text { 
			
					}
				}
			}

			.catalog-filter { 

				.catalog-filter-holder { 
			
					form { 
			
						.heading { 
							padding: 13px 15px 13px 20px;
							border-bottom: 1px solid $bg-secondary;
							.close { 
								float: right;
								.mobile-menu-close { 
			
									svg { 
			
										path { 
			
										}
									}
								}
							}
			
							.clear-filter { 
								float: left;
								button { 
                                    font-size: 14px;
                                    line-height: 160%;
                                    margin: 0;
                                    padding: 0;
                                    position: relative;
                                    color: $gray-dark;
                                    background: none;
                                    border: none;
                                    &:hover {
                                        color: $brand-primary;
                                        &:after {
                                            opacity: 0;
                                            transition: all 300ms;
                                        }
                                    }
                                    &:after {
                                        content: "";
                                        display: inline-block;
                                        width: 100%;
                                        position: absolute;
                                        left: 0;
                                        bottom: 2px;
                                        border-top: 1px dashed currentColor;
                                        transition: all 300ms;
                                    }
                                }
							}
						}

						.filter-block.action-block {
							margin: 16px 0 0;
						}
			
						.filter-block { 
							padding: 20px;
							.filter-block-holder { 
			
								.name { 
			
								}
			
								.filter-block-contain { 
			
									.price-range { 
			
										.slider-holder { 
			
											.slider { 
			
											}
										}
			
										.price-inputs { 
			
											label { 
			
												.name { 
			
												}
			
												.price-min-input { 
			
												}
											}
										}
			
										.represent-text { 
			
											.represent-from { 
			
												.represent-from-value { 
			
												}
											}
			
											.represent-to { 
			
												.represent-to-value { 
			
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../media/mobile/includes/mobile_menu";
@import "../media/tablet/includes/mobile_menu";
