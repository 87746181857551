.header-holder { 
    display: inline-block;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 4px rgba(71, 82, 87, 0.05);
	.header-top { 
        display: inline-block;
        width: 100%;
        height: 54px;
        overflow: hidden;
        background-color: $brand-secondary-accent;
        color: white;
        .wrapper-inside {
            height: 100%;
        }
		.header-top-holder { 
            display: inline-block;
            width: 100%;
            height: 100%;
            .footer-menu { 
                float: left;
                padding: 16px 0 0;
                nav { 

                    ul { 
                        margin: 0 -8px;
                        li { 
                            padding: 0 8px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 160%;
                            a { 
                                color: white;
                                &:hover {
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                }
            }

            .call-order { 
                float: right;
                width: 180px;
                height: 100%;
                margin: 0 6px 0 0;
                .btn { 
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 160%;
                    .icon { 
                        margin-right: 10px;
                        width: 16px;
                        height: 16px;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text { 

                    }
                }
            }
        }
	}

	.header-main { 
        display: inline-block;
        width: 100%;
        position: relative;
		.header-main-holder { 

            .mobile-menu { 
                float: left;
                .mobile-menu-open { 

                }
            }

            .logo { 
                float: left;
                padding: 11px 0 0;
                margin: 0 32px 0 0;
                a { 

                    svg { 

                    }
                }
            }

            .header-catalog-menu { 
                float: left;
                padding: 0 0 0;
                margin: 23px 10px 0 0;
                &:hover {
                    padding: 0 0 22px;
                    .header-catalog-menu-body { 
                        max-height: 1000px;
                        z-index: 1005;
                        transition: all 500ms;
                    }
                }
                .btn { 
                    width: 132px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon { 
                        float: left;
                        margin-right: 12px;
                        width: 16px;
                        height: 16px;
                        svg { 
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text { 
                        float: left;
                    }
                }

                .header-catalog-menu-body { 
                    display: inline-block;
                    width: 100%;
                    max-height: 0px;
                    background-color: white;
                    border-radius: 6px;
                    overflow: hidden;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    z-index: -1;
                    border-top: 1px solid $bg-secondary;
                    transition: all 500ms;
                    .wrapper-inside { 
                
                    }

                    .header-catalog-menu-body-holder { 
                        display: inline-block;
                        width: 100%;
                        position: relative;
                        .menu-list { 
                            display: inline-block;
                            width: 286px;
                            box-sizing: border-box;
                            border-right: 1px solid $bg-secondary;
                            >ul { 
                                display: inline-block;
                                width: 100%;
                                padding: 0;
                                margin: 0;
                                >li.has-sub-menu { 
                                    &.open {
                                        .sub-menu { 
                                            width: calc(100% - 286px);
                                            z-index: 2;
                                            transition: all 500ms;
                                            .sub-menu-holder { 
                                                opacity: 1;
                                                transition: all 500ms;
                                            }
                                        }

                                        >a {
                                            background-color: $brand-primary;
                                            color: white;
                                            .icon { 
                                                opacity: 1;
                                                transition: all 300ms;
                                            }
                                        }
                                    }
                                    .sub-menu { 
                                        position: absolute;
                                        z-index: 1;
                                        left: 286px;
                                        top: 0;
                                        width: 0px;
                                        height: 100%;
                                        background-color: white;
                                        overflow: hidden;
                                        transition: all 300ms;
                                        .sub-menu-holder { 
                                            display: inline-block;
                                            width: calc(62.7vw - 286px);
                                            box-sizing: border-box;
                                            padding: 30px 50px 0;
                                            opacity: 0;
                                            transition: all 300ms;
                                            >.name { 
                                                display: inline-block;
                                                width: 100%;
                                                font-size: 21px;
                                                font-style: normal;
                                                font-weight: 800;
                                                line-height: 140%;
                                                margin: 0 0 34px;
                                                span { 
                                                    color: $brand-primary;
                                                }
                                            }
                                            
                                            ul.col-3 {
                                                li {
                                                    width: 33.333%;
                                                }
                                            }
                                            ul { 
                                                display: inline-block;
                                                width: calc(94% + 46px);
                                                margin-left: -23px;
                                                li { 
                                                   float: left;
                                                    width: 100%;
                                                    box-sizing: border-box;
                                                    padding: 0 23px;
                                                    margin: 0 0 22px;
                                                    a { 
            
                                                    }
                                                }
                                            }
                                            ul.branded-links  {
                                                li {
                                                    margin: 0 0 14px;
                                                    a { 
                                                        display: flex;
                                                        align-items: center;
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        font-size: 14px;
                                                        line-height: 160%;
                                                        .image { 
                                                            width: 98px;
                                                            height: 38px;
                                                            border: 1px solid $gray-light;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            img { 
                                                                width: 70px;
                                                                height: 36px;
                                                                object-fit: contain;
                                                            }
                                                        }

                                                        .name { 
                                                        margin-left: 16px; 
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
            
                                >li { 
                                    display: inline-block;
                                    width: 100%;
                                    margin: 0 0 -1px;
                                    &:hover {
                                        >a {
                                            background-color: $brand-primary;
                                            color: white;
                                            .icon { 
                                                opacity: 1;
                                                transition: all 300ms;
                                            }
                                        }
                                    }
                                    >a { 
                                        display: inline-block;
                                        width: 100%;
                                        padding: 10px 16px;
                                        font-size: 14px;
                                        font-family: Manrope;
                                        line-height: 160%;
                                        position: relative;
                                        box-sizing: border-box;
                                        .text { 
                                            display: inline;
                                        }
            
                                        .icon { 
                                            position: absolute;
                                            right: 13px;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            opacity: 0;
                                            transition: all 300ms;
                                            width: 16px;
                                            height: 16px;
                                            color: white;
                                            svg { 
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .quick-search { 
                float: left;
                width: 380px;
                padding: 23px 0 0;
                margin: 0 33px 0 0;
                form { 

                    .form-group { 

                        .form-col { 

                           
                        }
                    }

                    .form-field { 

                        label.input { 

                            .name { 

                            }

                            input { 
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 160%;
                                padding: 0px 30px 0 16px;
                                @include placeholder {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 160%;
                                }
                            }

                            .addon { 
                                right: 15px;
                                button { 
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                    svg { 

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .header-contacts { 
                float: left;
                padding: 23px 0 0;
                .info-line { 
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 160%;
                    text-align: right;
                    margin: 0 0 4px;
                    .text { 
                        display: inline;
                        margin-right: 5px;
                    }

                    .info { 
                        display: inline;
                        font-size: 16px;
                        font-weight: 800;
                        a { 

                        }
                    }
                }
            }

            .header-peronal { 
                float: right;
                .personal { 
                    float: left;
                    padding: 38px 0 0;
                    a { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 160%;
                        .icon { 
                            float: left;
                            margin-right: 10px;
                            svg { 

                            }
                        }

                        .text { 
                            float: left;
                        }
                    }
                }

                .cart { 
                    float: left;
                    padding: 36px 0 0;
                    margin: 0 0 0 30px;                
                    a { 
                        font-style: normal;
                        font-weight: 800;
                        font-size: 16px;
                        line-height: 160%;
                        .icon { 
                            float: left;
                            position: relative;
                            color: $brand-primary;
                            margin-right: 10px;
                            svg { 

                            }

                            .items-in-cart { 
                                position: absolute;
                                right: 2px;
                                top: -5px;
                                font-style: normal;
                                font-weight: 800;
                                font-size: 13px;
                                line-height: 18px;
                            }
                        }

                        .text { 
                            float: left;
                            min-width: 85px;
                        }
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/header";
@import "../../media/mobile/includes/base/header";
